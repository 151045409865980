import { Input } from "antd";
import React, { useState, useRef } from "react";
import styled from "styled-components";
import MainImage from "./image01.png";
import { useNavigate } from "react-router-dom";
import emailjs from "emailjs-com";
import { Divider } from "antd";
import { Modal } from 'antd';

const DigicelForm = () => {
  const navigate = useNavigate();
  const form = useRef();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [firstName, setFirstName] = useState('')
  const [secondName, setSecondName] = useState('')
  const [company, setCompany] = useState('')
  const [phone, setPhone] = useState('')
  const [email, setEmail] = useState('')

  const handleClose = (e) => {
   setIsModalVisible(false)
   setFirstName('')
   setSecondName('')
   setCompany('')
   setPhone('')
   setEmail('')
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_7bnvi8a",
        "template_v6cxp7m",
        form.current,
        "4133NazwdPGXcDM9A"
      )
      .then(
        (result) => {
          console.log(result.text);
          setIsModalVisible(true);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <Container>
      <div>
        <img src={MainImage} />
        <Mainh1>JIVEMARKET IS FOR KUMULS LIKE YOU</Mainh1>
      </div>
      <Divider type="vertical" />
      <div>
        <ImageDiv>
          <img src={MainImage} />
        </ImageDiv>
        <FormHeader>
          <h1>Sign Up To Sell Digicel Flex Credits</h1>
          <p>
          If You Want To Sell Digicel Flex Credits, simply fill out the form below.
          </p>
      
        </FormHeader>
        <Form ref={form}>
          <div>
            <Input
              placeholder="First Name"
              name="firstName"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value) }
            />
          </div>
          <div>
            <Input
              placeholder="Second Name"
              name="secondName"
              value={secondName}
              onChange={(e) => setSecondName(e.target.value)}
            />
          </div>
          <div>
            <Input
              placeholder="Company or Store"
              name="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              
            />
          </div>
          <div>
            <Input
              placeholder="Phone"
              type="number"
              name="mobile"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              min="1"
              max="10"
            />
          </div>
          <div>
            <Input placeholder="Email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>
          <Btns>
            <CancelButton onClick={() => navigate("/")}>Cancel</CancelButton>
            {(firstName && secondName && company && phone.length === 8 && email.includes('@')) ?
              <SubmitButton onClick={sendEmail}>Submit</SubmitButton> :
              <DisabledBtn disabled >Submit</DisabledBtn>
            }
          </Btns>
        </Form>
      </div>
      <Modal title="Digicel Credits" visible={isModalVisible} onOk={handleClose} onCancel={handleClose}>
      <h3 style={{color: 'green'}}>Thankyou, our team will contact you shortly!</h3>
      </Modal>
    </Container>
    
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
  padding: 0 40px;
  height: auto;
  transform: translateY(20%);
  @media (max-width: 600px) {
    padding: 0 50px;
    -webkit-transform: translateY(18%);
    -ms-transform: translateY(18%);
    transform: translateY(18%);
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
  }

  div {
    img {
      @media (max-width: 600px) {
        display: none;
      }
    }
  }
`;

const ImageDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  width: 100px;
  display: none;
  img {
    width: 100%;
  }
  @media (max-width: 600px) {
    display: block;
  }
`;

const FormHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  justify-content: center;
  margin-bottom: 10px;
  color: #d52027;
  h1 {
    margin: 0;
    color: #d52027;
    @media (max-width: 600px) {
      font-size: 18px;
      text-align: center;
    }
  }
  h3 {
    color: green;
    margin: 0;
    margin-bottom: 10px;
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }
  p {
    font-size: 12px;
    margin: 0;
    @media (max-width: 600px) {
      font-size: 12px;
      text-align: center;
    }
  }
`;


const Mainh1 = styled.h1`
  @media (max-width: 600px) {
    display: none;
  }
`;
const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 20px;
  border-radius: 4px;
  @media (max-width: 600px) {
    padding: 0;
  }
  input {
    margin-top: 5px;
    @media (max-width: 600px) {
    height: 44px;
  }
  }
  label {
    font-weight: 500;
  }
`;

const Btns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 18px;
`;
const CancelButton = styled.button`
  border: 1px solid #d52027;
  color: #d52027;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 400;
  background-color: transparent;
  border-radius: 4px;
  width: 175px;
  transform: all 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }
  @media (max-width: 600px) {
    padding: 4px;
    width: 100px;
    font-size: 12px;
  }
`;
const SubmitButton = styled.button`
  border: 1px solid #d52027;
  color: #fff;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 400;
  background-color: #d52027;
  border-radius: 4px;
  width: 175px;
  transition: all 0.3s;
  cursor: pointer;
  &:hover {
    background-color: transparent;
    color: #d52027;
    border: 1px solid #d52027;
    font-weight: 600;
  }
  @media (max-width: 600px) {
    padding: 4px;
    width: 100px;
    font-size: 12px;
  }
`;

const DisabledBtn = styled.button`
 border: 1px solid #c6c3c4;
  color: #c6c3c4;
  padding: 5px 20px;
  font-size: 18px;
  font-weight: 400;
  border-radius: 4px;
  background-color: transparent;
  width: 175px;
  @media (max-width: 600px) {
    padding: 4px;
    width: 100px;
    font-size: 12px;
  }
`
export default DigicelForm;
