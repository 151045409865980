import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MainImage from "./image01.png";
import { Divider } from "antd";

const Home = () => {
  return (
    <Container>
      <div>
        <img src={MainImage} />
        <MainH1>JIVEMARKET IS FOR KUMULS LIKE YOU</MainH1>
      </div>
      <Divider type="vertical" />
      <Nav>
        <h1>Welcome!</h1>
        <Link to="/vodafone" style={{fontSize: '30px', color: '#d52027'}}>Sell Vodafone Credits</Link> |{" "}
        <Link to="/digicel" style={{fontSize: '30px', color:'#d52027'}}>Sell Digicel Flex Credits</Link>
      </Nav>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  max-width: 1140px;
  padding: 0 40px;
  height: auto;
  transform: translateY(20%);
  @media (max-width: 600px) {
    flex-direction: column;
    transform: translateY(10%);
    align-items: center;
  }

  div {
    @media (max-width: 600px) {
      width: 300px;
    }
    img {
      width: 100%;
      @media (max-width: 600px) {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;


const MainH1 = styled.h1`
@media (max-width: 600px) {
    font-size: 16px;
    text-align: center;
  }
`
const Nav = styled.nav`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  h1 {
    font-size: 64px;
    text-align: center;
    @media (max-width: 600px) {
    font-size: 24px;
    text-align: center;
  }
  }

  Link {
    text-align: center;
  }
`;
export default Home;
