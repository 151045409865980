import VodafoneForm from "./VodafoneForm";
import "./App.css";
import DigicelForm from "./DigicelForm";
import Home from "./Home";
import { Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <Routes>
        <Route index element={<Home />} />
        <Route path="vodafone" element={<VodafoneForm />} />
        <Route path="digicel" element={<DigicelForm />} />
      </Routes>
    </>
  );
}

export default App;
